
// Vue
import {Options, Vue} from "vue-class-component";

// Services
import {getTranslation} from "@/services/app/translation";

@Options({})
export default class AdministratorNavigationReportsVue extends Vue {
  exactRoute = "";

  get translation(): any {
    return getTranslation([
      "incomingInvoices",
      "outgoingInvoices",
      "placeOfCosts",
    ]);
  }

  setExactRoute(): void {
    switch (this.$route.name) {
      case "AppAdministratorReportsOutgoingInvoicesIndexVue":
        this.exactRoute = "outgoing-invoices";
        break;
      case "AppAdministratorReportsIncomingInvoicesIndexVue":
        this.exactRoute = "incoming-invoices";
        break;
      case "AppAdministratorReportsPlaceOfCostsIndexVue":
        this.exactRoute = "place-of-costs";
        break;
      case "AppAdministratorReportsPartnersIndexVue":
        this.exactRoute = "partners";
        break;
    }
  }

  created(): void {
    this.setExactRoute();
  }
}
